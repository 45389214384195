'use client';
import React from 'react';
import { Typography, Box, Grid, Icon } from '@mui/material';
import { useTheme } from '@/hooks/useTheme';
import { QrCodeIcon, ChartBarIcon, PaintBrushIcon, ClockIcon } from '@heroicons/react/24/solid';

interface Feature {
  title: string;
  description: string;
  icon: JSX.Element;
}

const features: Feature[] = [
  {
    title: "Dynamic QR Codes & Short Links",
    description: "Update content instantly without reprinting. Keep your audience engaged with current promotions and information.",
    icon: <QrCodeIcon className="h-6 w-6" />,
  },
  {
    title: "Real-Time Analytics",
    description: "Monitor scans, clicks, and user behavior. Optimize your campaigns with comprehensive data.",
    icon: <ChartBarIcon className="h-6 w-6" />,
  },
  {
    title: "Customizable Branding",
    description: "Personalize QR codes and links with your brand colors and logos for better recognition.",
    icon: <PaintBrushIcon className="h-6 w-6" />,
  },
  {
    title: "Time-Sensitive Controls",
    description: "Set content to change or expire at specific times, perfect for limited-time offers and events.",
    icon: <ClockIcon className="h-6 w-6" />,
  },
];

const FeaturesGrid: React.FC = () => {
  const { theme } = useTheme();
  return (
    <Box sx={{ py: theme.spacing(6) }}>
      <Typography variant="h2" component="h2" gutterBottom align="center">
        Why Choose Quiqr.me
      </Typography>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <Icon sx={{ maxWidth: '1.2rem', color: theme.palette.primary.main, display: 'flex' }}>
                {feature.icon}
              </Icon>
              <Typography variant="h6" component="h3" sx={{marginLeft:'0.3rem'}}>
                {feature.title}
              </Typography>
            </Box>
            <Typography variant="body1">
              {feature.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeaturesGrid;
