'use client';
import React from 'react';
import { Typography, Box, Card, CardContent } from '@mui/material';
import { useTheme } from '@/hooks/useTheme';

const useCases = [
  {
    industry: "Restaurants",
    description: "Easily update menus and daily specials without reprinting."
  },
  {
    industry: "Event Organizers",
    description: "Provide real-time event information and updates to attendees."
  },
  {
    industry: "Retail",
    description: "Run time-sensitive promotions and track engagement instantly."
  }
];

const UseCases: React.FC = () => {
  const { theme } = useTheme();
  return (
    <Box sx={{ py: theme.spacing(6) }}>
      {useCases.map((useCase, index) => (
        <Card key={index} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6" component="h3" gutterBottom>
              {useCase.industry}
            </Typography>
            <Typography variant="body1">
              {useCase.description}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default UseCases;


