'use client';
import React from 'react';
import { Breakpoint, Container, Typography } from '@mui/material';
import { useTheme } from '@/hooks/useTheme';

interface ContentLayoutProps {
  id: string;
  children: React.ReactNode;
  title?: string;
  description?: string;
  maxWidth?: Breakpoint | false;
  isOdd?: boolean;
}

const ContentLayout: React.FC<ContentLayoutProps> = ({ id, title, description, children, isOdd, maxWidth }) => {
  const { theme } = useTheme();
  const getTextAlign = () => {
    if (isOdd === undefined) return 'center';
    return isOdd ? 'right' : 'left';
  };
  
  return (
    <Container
      id={id}
      component="section"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxWidth: theme.breakpoints.values.lg,
        padding: `${theme.spacing(30, 2, 40)}`,
        margin: '0 auto',
        scrollMarginTop: 'var(--header-height)',
        textAlign: getTextAlign(),
      }}
    >

      <Container maxWidth={maxWidth}>
        {title && (
          <Typography variant="h2" component="h2" gutterBottom>
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body1" component="p" gutterBottom>
            {description}
          </Typography>
        )}
        {children}
      </Container >
    </Container>
  );
};

export default ContentLayout;
