import { useEffect, useState, useCallback, useRef } from 'react';
import { ThemeService } from '../services/ThemeService';
import { ThemeMode, ThemeVariant, ThemeTokens } from '../types/ThemeTypes';

export function useTheme() {
  // Use ref to keep themeService instance stable across renders
  const themeServiceRef = useRef(ThemeService.getInstance());
  const [mode, setMode] = useState<ThemeMode>('dark');
  const [variant, setVariant] = useState<ThemeVariant>('default');
  const [tokens, setTokens] = useState<ThemeTokens>(themeServiceRef.current['tokensSubject'].value);

  useEffect(() => {
    const service = themeServiceRef.current;
    const modeSubscription = service.getMode().subscribe(setMode);
    const variantSubscription = service.getVariant().subscribe(setVariant);
    const tokensSubscription = service.getTokens().subscribe(setTokens);

    return () => {
      modeSubscription.unsubscribe();
      variantSubscription.unsubscribe();
      tokensSubscription.unsubscribe();
    };
  }, []); // Now safe to omit themeService as it's in a ref

  const toggleTheme = useCallback(() => {
    themeServiceRef.current.toggleMode();
  }, []);

  const cycleTheme = useCallback(() => {
    themeServiceRef.current.cycleVariant();
  }, []);

  const updateTokens = useCallback((newTokens: Partial<ThemeTokens>) => {
    themeServiceRef.current.updateTokens(newTokens);
  }, []);

  return {
    mode,
    variant,
    tokens,
    toggleTheme,
    cycleTheme,
    updateTokens
  };
}