'use client';
import { useTheme } from "@/hooks/useTheme";
import * as React from "react";

const TEXT_PROPS = {
    fontFamily: 'var(--font-montserrat), sans-serif',
    fontWeight: 800,
    letterSpacing: "-0.12em",
    dominantBaseline: "middle",
    textAnchor: "middle",
};

const BASE_LAYERS = [{ fill: (theme: any) => theme.palette.primary.main, opacity: 0.3, dx: -3.2, dy: 2.6, }, { fill: (theme: any) => theme.palette.primary.contrastText, dx: -2.6, dy: 2.3, }, { fill: (theme: any) => theme.palette.primary.main, },];

interface LogoComponentProps extends React.SVGProps<SVGSVGElement> {
    size?: number;
}

const LogoComponent: React.FC<LogoComponentProps> = ({ size = 54, ...props }) => {
    const { theme } = useTheme();

    const adjustedFontSize = size;
    const adjustedHeight = (size / 54) * 42;
    const adjustedWidth = (adjustedHeight * 3);
    const viewBoxHeight = (adjustedHeight / 42) * 100;
    const viewBoxWidth = (adjustedWidth / 126) * 300;

    const scaledLayers = BASE_LAYERS.map(layer => ({
        ...layer,
        dx: (layer.dx || 0) * (size / 54),
        dy: (layer.dy || 0) * (size / 54),
    }));

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
            height={`${adjustedHeight}px`}
            width={`${adjustedWidth}px`}
            {...props}
        >
            {scaledLayers.map((layer, index) => (
                <text
                    key={index}
                    x="50%"
                    y="63%"
                    width={100}
                    height={100}
                    fill={layer.fill(theme)}
                    opacity={layer.opacity || 1}
                    dx={layer.dx || 0}
                    dy={layer.dy || 0}
                    style={{ fontSize: `${adjustedFontSize}px` }}
                    {...TEXT_PROPS}
                >
                    Quiqr.me
                </text>
            ))}
        </svg>
    );
};

export default LogoComponent;


