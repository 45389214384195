import { BehaviorSubject, Observable } from 'rxjs';
import { ThemeMode, ThemeVariant, ThemeTokens } from '../types/ThemeTypes';
import { baseTokens } from '../tokens/baseTokens';

export class ThemeService {
  private static instance: ThemeService;
  private modeSubject: BehaviorSubject<ThemeMode>;
  private variantSubject: BehaviorSubject<ThemeVariant>;
  private tokensSubject: BehaviorSubject<ThemeTokens>;

  private constructor() {
    const savedMode = this.getSavedMode();
    const savedVariant = this.getSavedVariant();
    
    this.modeSubject = new BehaviorSubject<ThemeMode>(savedMode);
    this.variantSubject = new BehaviorSubject<ThemeVariant>(savedVariant);
    this.tokensSubject = new BehaviorSubject<ThemeTokens>(baseTokens);
  }

  private getSavedMode(): ThemeMode {
    if (typeof window !== 'undefined') {
      return (localStorage.getItem('app-theme-mode') as ThemeMode) || 'dark';
    }
    return 'dark';
  }

  private getSavedVariant(): ThemeVariant {
    if (typeof window !== 'undefined') {
      return (localStorage.getItem('app-theme-variant') as ThemeVariant) || 'default';
    }
    return 'default';
  }

  public static getInstance(): ThemeService {
    if (!ThemeService.instance) {
      ThemeService.instance = new ThemeService();
    }
    return ThemeService.instance;
  }

  public getMode(): Observable<ThemeMode> {
    return this.modeSubject.asObservable();
  }

  public getVariant(): Observable<ThemeVariant> {
    return this.variantSubject.asObservable();
  }

  public getTokens(): Observable<ThemeTokens> {
    return this.tokensSubject.asObservable();
  }

  public toggleMode(): void {
    const newMode = this.modeSubject.value === 'light' ? 'dark' : 'light';
    this.modeSubject.next(newMode);
    if (typeof window !== 'undefined') {
      localStorage.setItem('app-theme-mode', newMode);
      document.documentElement.setAttribute('data-theme', newMode);
    }
  }

  public cycleVariant(): void {
    const currentVariant = this.variantSubject.value;
    const variants: ThemeVariant[] = ['default', 'halloween', 'bakery'];
    const currentIndex = variants.indexOf(currentVariant);
    const nextVariant = variants[(currentIndex + 1) % variants.length];
    
    this.variantSubject.next(nextVariant);
    if (typeof window !== 'undefined') {
      localStorage.setItem('app-theme-variant', nextVariant);
      document.documentElement.setAttribute('data-theme-variant', nextVariant);
    }
  }

  public updateTokens(newTokens: Partial<ThemeTokens>): void {
    const currentTokens = this.tokensSubject.value;
    this.tokensSubject.next({ ...currentTokens, ...newTokens });
  }
}


