'use client';
import { useTheme } from '@/theme/ThemeProvider';
import { Box, Typography, Fade } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface BackToTopContent {
    ctaText: string;
}

interface BackToTopContentProps {
    content?: BackToTopContent;
}

const BackToTop: React.FC<BackToTopContentProps> = ({ content = { ctaText: "back to top" } }) => {
    const [isVisible, setIsVisible] = useState(false);
    const { theme } = useTheme();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const toggleVisibility = () => {
        if (window.scrollY > window.innerHeight) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
        <Fade in={isVisible}>
            <Box
                sx={
                    {
                        position: 'fixed',
                        bottom: 0,
                        right: 16,
                        padding: theme.spacing(2),
                        backdropFilter: 'blur(2px)',
                        zIndex: 1000,
                    }
                }>
                <Typography
                    variant="caption"
                    component="a"
                    onClick={scrollToTop}
                    style={{
                        textDecoration: 'none', color: 'inherit', cursor: 'pointer', textAlign: 'center',
                    }}
                >
                    {content.ctaText}
                </Typography>
            </Box>
        </Fade>
    );
};

export default BackToTop;
