'use client';
import React, { useState } from 'react';
import { Typography, Button, TextField, Box, Alert } from '@mui/material';
import { useTheme } from '@/hooks/useTheme';

interface SignUpContent {
  title: string;
  inputLabel: string;
  buttonText: string;
}

interface WaitlistProps {
  formContent: SignUpContent;
}

const Waitlist: React.FC<WaitlistProps> = ({ formContent }) => {
  const { theme } = useTheme();
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await fetch('/api/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage(data.message);
        setEmail('');
      } else {
        setErrorMessage(data.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      alignSelf='center'
      justifySelf='center'
      component="form"
      onSubmit={handleSubmit}
      textAlign='center'
      noValidate
      autoComplete="off"
      sx={{
        maxWidth: 600,
        margin: '4rem 0 6rem',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <Typography variant="h4" gutterBottom>
        {formContent.title}
      </Typography>
      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
      {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
      <TextField
        label={formContent.inputLabel}
        variant="outlined"
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        required
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: theme.spacing(2) }}
        disabled={loading}
      >
        {loading ? 'Submitting...' : formContent.buttonText}
      </Button>
    </Box>
  );
};

export default Waitlist;
