// src/theme/ThemeProvider.tsx
'use client';

import React, { createContext, useContext } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeMode, ThemeVariant, ThemeTokens } from './types/ThemeTypes';
import { ThemeService } from './services/ThemeService';
import { createTheme } from '@mui/material/styles';
import { useTheme as useThemeBase } from './hooks/useTheme';

interface ThemeContextType {
  mode: ThemeMode;
  variant: ThemeVariant;
  tokens: ThemeTokens;
  toggleTheme: () => void;
  cycleTheme: () => void;
  updateTokens: (newTokens: Partial<ThemeTokens>) => void;
  theme: ReturnType<typeof createTheme>;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const themeValue = useThemeBase();
  const theme = createTheme({
    palette: {
      mode: themeValue.mode,
      primary: {
        main: themeValue.tokens.colors.primary[themeValue.mode],
      },
      secondary: {
        main: themeValue.tokens.colors.secondary[themeValue.mode],
      },
      background: {
        default: themeValue.tokens.colors.background[themeValue.mode],
        paper: themeValue.tokens.colors.surface[themeValue.mode],
      },
      text: {
        primary: themeValue.tokens.colors.textPrimary[themeValue.mode],
        secondary: themeValue.tokens.colors.textSecondary[themeValue.mode],
      },
    },
    typography: themeValue.tokens.typography,
    spacing: (factor: number) => `${0.25 * factor}rem`,
    shape: {
      borderRadius: parseInt(themeValue.tokens.borderRadius.default.value),
    },
    breakpoints: {
      values: {
        xs: parseInt(themeValue.tokens.breakpoints.xs.value),
        sm: parseInt(themeValue.tokens.breakpoints.sm.value),
        md: parseInt(themeValue.tokens.breakpoints.md.value),
        lg: parseInt(themeValue.tokens.breakpoints.lg.value),
        xl: parseInt(themeValue.tokens.breakpoints.xl.value),
      },
    },
  });

  const contextValue: ThemeContextType = {
    ...themeValue,
    theme,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export default ThemeProvider;