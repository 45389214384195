'use client';
import React from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@/hooks/useTheme';

const faqs = [
  {
    question: "How often can I update my QR code content?",
    answer: "You can update your QR code content as often as you like, with no limitations."
  },
  {
    question: "Do I need to create a new QR code every time I want to change the content?",
    answer: "No, you can use the same QR code and simply update the linked content through our dashboard."
  },
  {
    question: "Can I see how many times my QR code has been scanned?",
    answer: "Yes, our real-time analytics provide detailed information on scans, including time and location data."
  }
];

const FAQSection: React.FC = () => {
  const { theme } = useTheme();
  return (
    <Box sx={{ py: theme.spacing(6) }}>
      <Typography variant="h2" component="h2" gutterBottom align="center">
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" sx={{ padding: theme.spacing(2) }}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ padding: theme.spacing(3) }} >{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQSection;


