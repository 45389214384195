import { ThemeTokens } from "../types/ThemeTypes";

export const baseTokens: ThemeTokens = {
  colors: {
    primary: {
      light: '#212121',
      dark: '#E0E0E0',
      value: '',
      description: 'Primary brand color'
    },
    secondary: {
      light: '#FF4081',
      dark: '#F48FB1',
      value: '',
      description: 'Secondary brand color'
    },
    background: {
      light: '#F5F5F5',
      dark: '#121212',
      value: '',
      description: 'Background color'
    },
    surface: {
      light: '#FFFFFF',
      dark: '#1E1E1E',
      value: '',
      description: 'Surface color'
    },
    textPrimary: {
      light: '#212121',
      dark: '#E0E0E0',
      value: '',
      description: 'Primary text color'
    },
    textSecondary: {
      light: '#757575',
      dark: '#BDBDBD',
      value: '',
      description: 'Secondary text color'
    },
    error: {
      light: '#F44336',
      dark: '#EF9A9A',
      value: '',
      description: 'Error state color'
    },
    warning: {
      light: '#FFA000',
      dark: '#FFCC80',
      value: '',
      description: 'Warning state color'
    },
    info: {
      light: '#2196F3',
      dark: '#90CAF9',
      value: '',
      description: 'Info state color'
    },
    success: {
      light: '#4CAF50',
      dark: '#A5D6A7',
      value: '',
      description: 'Success state color'
    }
  },
  typography: {
    h1: {
      fontSize: '1.6rem',
      fontWeight: 700,
      lineHeight: 1.2,
      fontFamily: 'var(--font-montserrat), sans-serif',
      value: '',
      description: 'Heading 1'
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.3,
      fontFamily: 'var(--font-montserrat), sans-serif',
      value: '',
      description: 'Heading 2'
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
      lineHeight: 1.3,
      fontFamily: 'var(--font-montserrat), sans-serif',
      value: '',
      description: 'Heading 3'
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.35,
      fontFamily: 'var(--font-montserrat), sans-serif',
      value: '',
      description: 'Heading 4'
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      fontFamily: 'var(--font-open-sans), sans-serif',
      value: '',
      description: 'Body text 1'
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.43,
      fontFamily: 'var(--font-open-sans), sans-serif',
      value: '',
      description: 'Body text 2'
    }
  },
  spacing: {
    xs: {
      value: '4px',
      description: 'Extra small spacing'
    },
    sm: {
      value: '8px',
      description: 'Small spacing'
    },
    md: {
      value: '16px',
      description: 'Medium spacing'
    },
    lg: {
      value: '24px',
      description: 'Large spacing'
    },
    xl: {
      value: '32px',
      description: 'Extra large spacing'
    }
  },
  breakpoints: {
    xs: {
      value: '0px',
      description: 'Extra small breakpoint'
    },
    sm: {
      value: '600px',
      description: 'Small breakpoint'
    },
    md: {
      value: '960px',
      description: 'Medium breakpoint'
    },
    lg: {
      value: '1280px',
      description: 'Large breakpoint'
    },
    xl: {
      value: '1920px',
      description: 'Extra large breakpoint'
    }
  },
  borderRadius: {
    default: {
      value: '6px',
      description: 'Default border radius'
    },
    small: {
      value: '4px',
      description: 'Small border radius'
    },
    large: {
      value: '8px',
      description: 'Large border radius'
    },
    pill: {
      value: '999px',
      description: 'Pill border radius'
    }
  },
  elevation: {
    level1: '0px 1px 3px rgba(0,0,0,0.12), 0px 1px 2px rgba(0,0,0,0.24)',
    level2: '0px 3px 6px rgba(0,0,0,0.16), 0px 3px 6px rgba(0,0,0,0.23)',
    level3: '0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23)'
  },
  opacity: {
    low: '0.2',
    medium: '0.5',
    high: '0.8'
  },
  transitions: {
    default: 'all 0.3s ease',
    fast: 'all 0.2s ease',
    slow: 'all 0.5s ease'
  }
};


