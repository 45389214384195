'use client';
import React from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@/hooks/useTheme';

const ComparisonTable: React.FC = () => {
 const { theme } = useTheme();
 return (
   <Box sx={{ py: theme.spacing(6) }}>
     <Typography variant="h2" component="h2" gutterBottom align="center">
       Quiqr.me vs Traditional QR Codes
     </Typography>
     <Table>
       <TableHead>
         <TableRow>
           <TableCell>Feature</TableCell>
           <TableCell>Quiqr.me</TableCell>
           <TableCell>Traditional QR Codes</TableCell>
         </TableRow>
       </TableHead>
       <TableBody>
         <TableRow>
           <TableCell>Content Updates</TableCell>
           <TableCell>Instant, unlimited</TableCell>
           <TableCell>Requires reprinting</TableCell>
         </TableRow>
         <TableRow>
           <TableCell>Analytics</TableCell>
           <TableCell>Real-time, detailed</TableCell>
           <TableCell>Limited or none</TableCell>
         </TableRow>
         <TableRow>
           <TableCell>Customization</TableCell>
           <TableCell>Fully brandable</TableCell>
           <TableCell>Limited</TableCell>
         </TableRow>
       </TableBody>
     </Table>
   </Box>
 );
};

export default ComparisonTable;


