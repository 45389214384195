'use client';
import React from 'react';
import { Typography, Box, Stepper, Step, StepLabel } from '@mui/material';
import { useTheme } from '@/hooks/useTheme';

const steps = [
  'Create your dynamic QR code or short link',
  'Place it in your marketing materials',
  'Update content anytime through the dashboard',
  'Track engagement with real-time analytics'
];

const HowItWorks: React.FC = () => {
  const { theme } = useTheme();
  return (
    <Box sx={{ py: theme.spacing(6) }}>
      <Typography variant="h2" component="h2" gutterBottom align="center">
        How It Works
      </Typography>
      <Stepper activeStep={-1} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default HowItWorks;


